import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  setSnackBarAlert,
  OnSuccessSetProfileOverview,
  updateSessionProfileName,
  updateSessionThemeName,
  caughtError } from '../../redux';

import getAppConfig from '../../config';
const config = getAppConfig();


const EditProfile = (props) => {
  const [teams, setTeams] = useState([]);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [theme, setTheme] = useState('');
  const [teamId, setTeamId] = useState('');
  const [darktheme, setDarkTheme] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch();

  const getAllTeams = (event) => {
    axios.post(`${config.api.URL}/me/get-all-teams-first-time-login`,{
      requestType: 'PUBLIC-REQUEST',
      requestCode: 'q$JNb=hS#TWL8dD^abRXh7%8CXrP67',
    }).then(response => {
      setTeams(response.data.data)
    }).catch(error => {
      console.log(`public/all-teams error => `,error);
    })
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'mobile':
        setMobile(value);
        break;
      case 'theme':
        setTheme(value);
        break;
      case 'teamId':
        setTeamId(value);
        break;
      case 'darktheme':
        setDarkTheme(value);
        if(value) {
          setTheme('dark');
        } else {
          setTheme('light');
        }
        break;
      default:
        console.log("Invalid form input");
    }

  }

  const handleSubmit = (event) => {
     event.preventDefault();
     setBtnDisabled(true);
     const input = {
       name: name,
       mobile: mobile,
       theme: theme,
       teamId: teamId
     }

     axios.patch(`${config.api.URL}/me/update-profile`, {
       ...input
     }).then(response => {
       setBtnDisabled(false);
       console.log("response.data.data.allowTeamChange>",response.data.data.allowTeamChange);

       if(response.data.data.allowTeamChange === false) {
         dispatch(setSnackBarAlert({
           openSnackBar: true,
           severity: 'warning',
           message: `Warning! profile updated but you are not allowed to change the team`
         }));
       } else {
         dispatch(setSnackBarAlert({
           openSnackBar: true,
           severity: 'success',
           message: `Success! profile updated`
         }));
       }


       dispatch(OnSuccessSetProfileOverview(response));
       dispatch(updateSessionProfileName(response.data.data.name));
       dispatch(updateSessionThemeName(response.data.data.theme));

       if(response?.data?.data?.userTeamChanged && response.data.data.userTeamChanged) {
         window.location.reload();
       }
     }).catch(error => {
       setBtnDisabled(false);
       dispatch(caughtError(error));
     })

   }

   useEffect(() => {
     getAllTeams();
     setName(props.profile.name ? props.profile.name.trim() : '');
     setMobile(props.profile.mobile ? props.profile.mobile.trim() : '');
     setTheme(props.profile.theme ? props.profile.theme.trim() : '');
     setTeamId(props.session.teamId ? props.session.teamId : '');
     setDarkTheme(props.profile.theme && props.profile.theme.trim() === 'dark' ? true : false);
   }, [props]);

   let teamsSelect = '';
   if(teams.length > 0) {
     teamsSelect = teams.map( (row) => <MenuItem key={`${row.id}`} value={`${row.id}`} >
     {row.teamName}
     </MenuItem>);
   }


   return (
     <div className="col-6">
     <form onSubmit={handleSubmit}>
     <div className="row mb-3">
       <label className="col-md-4 col-lg-3 col-form-label">Email</label>
       <div className="col-md-8 col-lg-9">
         {props.profile.email}
       </div>
     </div>

     <div className="row mb-3">
       <label className="col-md-4 col-lg-3 col-form-label">Full Name</label>
       <div className="col-md-8 col-lg-9">
         <input required onChange={handleInputChange} name="name" type="text" className="form-control" value={name}  />
       </div>
     </div>

     <div className="row mb-3">
       <label className="col-md-4 col-lg-3 col-form-label">Contact Number</label>
       <div className="col-md-8 col-lg-9">
         <input onChange={handleInputChange} name="mobile" type="text" className="form-control" value={mobile}  />
       </div>
     </div>

     <div className="row mb-3">
       <label className="col-md-4 col-lg-3 col-form-label">Team</label>
       <div className="col-md-8 col-lg-9">
         { teamsSelect !=='' && <Select
           fullWidth
           label="Team"
           size="small"
           name="teamId"
           value={teamId}
           onChange={handleInputChange}
         >
          <MenuItem value={'NOT_SET'}>-- SELECT YOUR TEAM --</MenuItem>
           {teamsSelect}
         </Select>}
         <br/>
         {/*<small>(Note: on team change, page will reload once to keep your user session active)</small>*/}
       </div>
     </div>

     <div className="row mb-3">
       <label className="col-md-4 col-lg-3 col-form-label">Dark Theme</label>
       <div className="col-md-8 col-lg-9">
         <Switch
         name="darktheme"
         checked={darktheme}
         onChange={handleInputChange} />
       </div>
     </div>

     <div className="row mb-3">
       <label className="col-md-4 col-lg-3 col-form-label"></label>
       <div className="col-md-8 col-lg-9">
         <button disabled={btnDisabled} type="submit" className="btn btn-primary">Save Changes</button>
       </div>
     </div>

    </form>
  </div>
   );
}

export default EditProfile;
